//React
import React from 'react';
//Components
import Explorer from '../../components/Explorer';

function Main() {
  return (
    <>
      <Explorer />
    </>
  );
}

export default Main;
